import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormControl } from "@angular/forms";
import {
  CustomerFinderAccountViewModel,
  CustomerFinderQuery,
  LookupClient,
  MyAccountAccountLookupViewModel,
  MyAccountAccountsClient,
  MyAccountLookupBy,
  ProvinceLookupViewModel,
} from "src/app/apis/customer-care-api.generated";
import { CallIdService } from "src/app/shared/five9Call/five9Call.service";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";

@Component({
  selector: "app-my-account-user-lookup",
  templateUrl: "./my-account-customer-finder.component.html",
  styleUrls: ["./my-account-customer-finder.component.scss"],
})
export class MyAccountCustomerFinderComponent implements OnInit {
  constructor(
    private myAccountAccountsClient: MyAccountAccountsClient,
    private lookupClient: LookupClient,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private callIdService: CallIdService
  ) {}

  isLoading = false;
  isLoadingAccount = false;

  accountNumber = new FormControl("");
  accountName = new FormControl("");
  province = new FormControl("");
  phone = new FormControl("");
  postal = new FormControl("");
  address = new FormControl("");
  siteNumber = new FormControl("");
  siteName = new FormControl("");
  callId = new FormControl("");

  provinces: ProvinceLookupViewModel[];
  accounts: CustomerFinderAccountViewModel[];
  sites: CustomerFinderAccountViewModel[];
  account: MyAccountAccountLookupViewModel;

  displayedAccountColumns: string[] = [
    "accountNumber",
    "accountName",
    "sourceSystem",
    "billTo",
    "address",
    "city",
    "province",
    "postal",
    "contactEmail",
    "contactPhone",
  ];

  displayedSiteColumns: string[] = [
    "siteName",
    "siteNumber",
    "accountNumber",
    "accountName",
    "sourceSystem",
    "address",
    "city",
    "province",
    "postal",
    "contactEmail",
    "contactPhone",
  ];

  get canSearch() {
    return !this.isLoading && !this.isLoadingAccount;
  }

  get found() {
    return (
      (this.accounts && this.accounts.length) ||
      (this.sites && this.sites.length)
    );
  }

  get showResult() {
    return !this.account && !this.isLoadingAccount;
  }

  getProvinceId = (u: ProvinceLookupViewModel) => u.id;

  getProvinceName = (u: ProvinceLookupViewModel) => u.provinceName;

  getProvinces() {
    this.lookupClient.getAllProvinces().subscribe((response) => {
      this.provinces = response.result;
    });
  }

  removeNonAlphaNumeric(str : string)
  {
    var result = "";
    for (var i = 0; i < str.length; i++)
    {
      if(this.isLetterOrNumber(str.charAt(i)))
      result += str.charAt(i).toUpperCase();
    }
    return result;
  }

  isLetterOrNumber(character : string)
  {
    var code = character.charCodeAt(0);
    if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
      return false;
        }
        return true;
  }

  isLetter (character : string)
  {
    var code = character.charCodeAt(0);
    if (!(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
      return false;
        }
        return true;
  }

  padAccountNumber(str : string)
  {
    var result = "";
    var length = str.length;
    var erpCode = str.substring(0,2);
    var number = str.substring(2);

    result = erpCode;
    // 9 is the length of account numbers
    for(var i = 0; i < 9 - length; i++)
    {
      result += '0';
    }
    result += number;
    return result;
  }

  search() {
    this.accounts = null;
    this.sites = null;
    this.account = null;
    this.isLoading = true;

    const query = new CustomerFinderQuery();

    if (this.accountNumber.value.trim().length)
    {
      var formattedAccountNumber = this.removeNonAlphaNumeric(this.accountNumber.value.trim());
      if(formattedAccountNumber.length != 9)
      {
        if(this.isLetter(formattedAccountNumber.charAt(0)))
        {
          // ex: A1123 -> A10000123
          formattedAccountNumber = this.padAccountNumber(formattedAccountNumber);
          query.accountNumber = formattedAccountNumber;
        }
        else
        {
          // convert to int
          var removeLeadingZeroes = parseInt(formattedAccountNumber);
          if(!isNaN(removeLeadingZeroes) && removeLeadingZeroes != 0)
          {
            formattedAccountNumber = removeLeadingZeroes + ""
            query.erpAccountNumberOnly = formattedAccountNumber;
          }
          else
          {
            query.accountNumber = formattedAccountNumber;
          }
        }
      }
      else
      {
        query.accountNumber = formattedAccountNumber;
      }
      this.accountNumber.setValue(formattedAccountNumber);
    }
    // if (this.accountName.value.trim().length)
    //   query.accountName = this.accountName.value.trim();
    if (this.province.value) query.province = this.province.value;
    if (this.phone.value.trim().length)
    {
      var formattedPhoneNumber = this.removeNonAlphaNumeric(this.phone.value.trim());
      query.phone = formattedPhoneNumber;
    } 
    if (this.postal.value.trim().length)
      query.postal = this.postal.value.trim();
    if (this.address.value.trim().length)
      query.address = this.address.value.trim();
    // if (this.siteName.value.trim().length)
    //   query.siteName = this.siteName.value.trim()
    if (this.siteNumber.value.trim().length)
    {
      var formattedSiteNumber = this.removeNonAlphaNumeric(this.siteNumber.value.trim());
      query.siteNumber = formattedSiteNumber;
      this.siteNumber.setValue(formattedSiteNumber);
    }
      

    this.myAccountAccountsClient.customerFinder(query).subscribe(
      (x) => {
        this.isLoading = false;
        this.accounts = x.result.accounts;
        this.sites = x.result.sites;
      },
      (error) => {
        this.isLoading = false;
        this.snackBarService.ShowError(
          "Customer Finder Search Error: " + error
        );
      }
    );
  }

  getAccount(accountNumber: string) {
    this.account = null;
    this.isLoadingAccount = true;

    this.myAccountAccountsClient
      .searchForMyAccountAccounts(accountNumber, MyAccountLookupBy.AccountNumber)
      .subscribe(
        (x) => {
          this.isLoadingAccount = false;
          this.account = x.result;
          window.scrollTo({ top: 80 });
        },
        (error) => {
          this.isLoadingAccount = false;
          this.snackBarService.ShowError(
            "Search for MyAccount account error: " + error
          );
        }
      );
  }

  getSite(siteNumber: string)
  {
    this.account = null;
    this.isLoadingAccount = true;

    this.myAccountAccountsClient
      .searchForMyAccountAccounts(siteNumber, MyAccountLookupBy.SiteNumber)
      .subscribe(
        (x) => {
          this.isLoadingAccount = false;
          this.account = x.result;
          window.scrollTo({ top: 80 });
        },
        (error) => {
          this.isLoadingAccount = false;
          this.snackBarService.ShowError(
            "Search for MyAccount site error: " + error
          );
        }
      );
  }

  ngOnInit() {
    this.getProvinces();

    this.route.queryParams.subscribe((params) => {
      // Define a mapping of query parameters to form controls
      const paramMap = {
        accountNumber: this.accountNumber,
        phone: this.phone,
        postal: this.postal,
        address: this.address,
        siteNumber: this.siteNumber,
      };

      debugger;
      // check if there is a callid in the query params
      if (params.callId) {
        this.callId.setValue(params.callId);
        this.callIdService.setCallId(params.callId);
      }
  
      // Check each parameter and update the corresponding form control
      Object.keys(paramMap).forEach((param) => {
        if (params[param]) {
          paramMap[param].setValue(params[param]);
        }
      });
  
      // Trigger the search if any relevant parameter is present
      if (Object.keys(paramMap).some((param) => params[param])) {
        this.search();
      }
    });
  }
}
