import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AssignToUserViewModel, CancelFuturePaymentCommand, ReportsClient, ResendPaymentEmailCommand, UsersClient } from 'src/app/apis/customer-care-api.generated';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';


export interface ICancelFuturePaymentDialogInputData {
  eventId:number
}

@Component({
  selector: 'app-cancel-future-payment-dialog',
  templateUrl: './cancel-future-payment-dialog.component.html',
  styleUrls: ['./cancel-future-payment-dialog.component.scss']
})



export class CancelFuturePaymentDialogComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();


  isLoading = false;


  constructor(private snackBarService: SnackBarService, private dialog: MatDialogRef<CancelFuturePaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICancelFuturePaymentDialogInputData,
    private reportClient: ReportsClient
  ) { }

  ngOnInit() {

  }

  onEmailClicked()
  {
    const request = new CancelFuturePaymentCommand(
      {
          eventLogId: this.data.eventId,
          isVoid : false
      }
    );
    this.isLoading = true;
    this.subscription.add(
      this.reportClient
        .cancelFuturePayment(
          request
        )
        .subscribe(
          (result) => {
            this.isLoading = false;
            this.snackBarService.ShowSuccess("Payment Cancelled Successfully");
            this.dialog.close(true);
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            this.snackBarService.ShowError(error);
          }
        )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
