import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import {
  CreatePaymentLinkCommand,
  CSRBillToEnum,
  MyAccountAccountLookupViewModel,
  MyAccountAccountsClient,
  MyAccountLookupBy,
  MyAccountSiteViewModel,
  PaymentLinkClient,
  PaymentTypeEnum,
  SecureCheckoutLinkEnum,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/shared/auth/auth.service";

@Component({
  selector: "app-my-account-user-create-link",
  templateUrl: "./create-link.component.html",
  styleUrls: ["./create-link.component.scss"],
})
export class CreateLinkComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input() depositsSelected: boolean;
  @Input() isIVR: boolean;
  @Input() accountNumberFromQuery: string;
  @Input() userEmails: string;
  @Input() userPhoneNumbers: string;

  isLoading: boolean;

  filterFormGroup = new FormGroup({
    siteId: new FormControl(null),
    email: new FormControl(null),
    phone: new FormControl(null),
  });


  showDeposits = false;

  showInvoices = false;

  isBillTo3 = false;

  showGuestPortalFee : boolean = true;

  subtotal: number = 0;
  guestPortalFee: number = 4.95;
  total: number = 0;
  includeGuestPortalFee: boolean = true;

  sites : MyAccountSiteViewModel[] = [];

  userEmailsArray: string[] = [];
  userPhoneNumbersArray: string[] = [];

  account: MyAccountAccountLookupViewModel;

  hasSelectedASite = false;

  depositsEntered = false

  id = new FormControl(0);
  customerEmail = new FormControl("");
  accountNumber = new FormControl("");
  customerPhone = new FormControl("");
  deposit = new FormControl(0);

  accountLoaded = false;

  currentUserId: number = 0;


  constructor(
    private myAccountAccountsClient: MyAccountAccountsClient,
    private paymentLinkClient: PaymentLinkClient,
    private snackBarService: SnackBarService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    this.filterFormGroup.controls.siteId.valueChanges.subscribe(value => {
      if (value != null) {
      this.hasSelectedASite = true;
      this.accountLoaded = true;
      }
      else{
        this.hasSelectedASite = false;
        this.accountLoaded = false;
      }
    });
    this.deposit.valueChanges.subscribe(value => {
      if (value != null && value >= 0) {
        this.depositsEntered = true;
        this.recalculateSubtotal();
        }
      else{
        this.depositsEntered = false;
      }
      });

      this.accountNumber.valueChanges.subscribe(value => {
        this.filterFormGroup.controls.siteId.setValue(null);
        this.isBillTo3 = false;
        });

    this.authService.currentUser.subscribe(
      (response) => {
        this.currentUserId = response.id;
      }
    );
    this.showDeposits = false;
    this.showInvoices = false;
    if(this.accountNumberFromQuery && this.accountNumberFromQuery.length > 0){
      this.accountNumber.setValue(this.accountNumberFromQuery);
      this.search();
    }
    if(this.userEmails && this.userEmails.length > 0){
      this.userEmailsArray = this.userEmails.split(',');
    }
    if(this.userPhoneNumbers && this.userPhoneNumbers.length > 1){
      this.userPhoneNumbersArray = this.userPhoneNumbers.split(',');
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onDepositClicked() {
    this.isLoading = true;
    this.showDeposits = true;
    this.showInvoices = false;
    this.isLoading = false;
  }

  getAssigneeId = (site: MyAccountSiteViewModel) => site.siteNumber;
  getAssigneeName = (site: MyAccountSiteViewModel) => site.siteNumber === null ? '' : site.siteNumber;

  getEmailId = (email: string) => email;
  getEmailName = (email: string) => email;

  getPhoneId = (phone: string) => phone;
  getPhoneName = (phone: string) => phone;

  get isValidAccountNumber() {
    return (this.accountNumber.value || "").trim().length > 0;
  }


  
  search() {
    this.account = null;
    this.filterFormGroup.controls.siteId.setValue(null);
    this.isLoading = true;

    var number = "";
    var lookupBy : MyAccountLookupBy;

    if (this.isValidAccountNumber)
    {
      number = this.accountNumber.value.trim();
      lookupBy = MyAccountLookupBy.AccountNumber;
    }
    else
    {
      this.isLoading = false;
          this.snackBarService.ShowError(
            "Please enter a value for one of the fields."
          );
    }

    this.myAccountAccountsClient
      .searchForMyAccountAccounts(number, lookupBy)
      .subscribe(
        (x) => {
          if (x.result == null) {
            this.isLoading = false;
            this.snackBarService.ShowError(
              "No account found for the entered number. Please try again."
            );
            return;
          }
          this.account = x.result;
          this.includeGuestPortalFee = this.account.billingRegion == "US";
          this.showGuestPortalFee = this.account.billingRegion == "US";
          this.total = this.includeGuestPortalFee ? this.guestPortalFee : 0;
          if (this.account && this.account.billToEnum == CSRBillToEnum.SiteARSite)
          {
            this.isBillTo3 = true;
            this.sites = this.account.sites;
          }
          else{
            this.accountLoaded = true;
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(
            "Search for MyAccount Account Error: " + error
          );
        }
      );
  }

  recalculateSubtotal(): void {
    this.subtotal = this.deposit.value;
    this.total = this.subtotal + (this.includeGuestPortalFee ? this.guestPortalFee : 0);
  }


  createDepositLink() {
    //check if there is a callId in the session storage
    debugger;
    const five9CallId = sessionStorage.getItem("callId");
    this.isLoading = true;
    if (!this.isIVR) {
      // Check if either customerEmail or customerPhone is valid
      const phoneControl = this.filterFormGroup.controls.phone;
      const emailControl = this.filterFormGroup.controls.email;

      // Regular expression for validating an email address
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      let isFormValid = false;

      if (phoneControl.value && emailControl.value) {
        // If both have values, check if both are valid and email matches regex
        isFormValid = phoneControl.valid && emailControl.valid && emailRegex.test(emailControl.value);
      } else if (phoneControl.value || emailControl.value) {
        // If only one has a value, check if the one with value is valid
        // For email, also check if it matches the regex
        if (phoneControl.value) {
          isFormValid = phoneControl.valid;
        } else {
          isFormValid = emailControl.valid && emailRegex.test(emailControl.value);
        }
      } else {
        // If neither have values, you might consider the form invalid or handle accordingly
        isFormValid = false; // or true, based on your requirements
      }

      if (!isFormValid) {
        this.snackBarService.ShowError("Please enter a valid email or phone number");
        this.isLoading = false;
        return;
      }
    }

    // Check if accountNumber is valid
    if (!this.accountNumber || this.accountNumber.value.length < 8) {
        this.snackBarService.ShowError("Account Numbers must be eight characters long");
        this.isLoading = false;
        return;
    }

    // Check if there is a valid dollar amount for the deposit
    if (!this.deposit || this.deposit.value <= 0) {
        this.snackBarService.ShowError("Deposit must be a valid amount");
        this.isLoading = false;
        return;
    }

    const paymentLink = new CreatePaymentLinkCommand({
      customerEmailAddress: this.filterFormGroup.controls.email.value,
      accountNumber: this.accountNumber.value,
      totalAmount: this.deposit.value,
      createdByCsrId: this.currentUserId,
      paymentType: this.isIVR ? PaymentTypeEnum.IVR : PaymentTypeEnum.CheckoutLink,
      linkType: SecureCheckoutLinkEnum.OnAccount,
      siteNumber: this.filterFormGroup.controls.siteId.value,
      customerPhoneNumber: this.filterFormGroup.controls.phone.value,
      hasConvenienceFee: this.includeGuestPortalFee,
      paymentTotalWithConvenienceFee: this.total,
      five9CallId: five9CallId
    });
    this.subscription.add(
      this.paymentLinkClient.createPaymentLink(
        paymentLink
      ).subscribe((result) => {
        this.isLoading = false;
        if (result.isSuccess) {
          this.snackBarService.ShowSuccess("The link has been created successfully !");
          this.router.navigateByUrl("/payment-link/view/"+ result.result.id);
        }
      },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError("Create Payment Link Error : " + error);
        }
      )
    );
  }

  onInvoicesClicked() {
    this.isLoading = true;
    // query to get invoices to display based on site/account number

    this.showInvoices = true;
    this.showDeposits = false;
    this.isLoading = false;
  }

  get myAccountAccountFound() {
    return (this.account &&
      (this.account.usersFromAccount ||
        this.account.usersFromAccount.length ||
        this.account.usersFromSite ||
        this.account.usersFromSite.length ||
        this.account.sites ||
        this.account.sites.length ||
        this.account.invoices ||
        this.account.invoices.length ||
        this.account.services ||
        this.account.services.length)
    );
  }



  get isValidLengthAccountNumber() {
    return (this.accountNumber.value || "").trim().length > 0 && ((this.accountNumber.value || "").trim().length < 9 || (this.accountNumber.value || "").trim().length > 9);
  }

  get isValidSearch() {
    // only valid if exactly one of the values is valid

    if (this.isValidAccountNumber) {
      return true;
    }
    return false;
  }

}
