import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import {
  CreatePaymentLinkCommand,
  MyAccountAccountsClient,
  MyAccountInvoicesViewModel,
  MyAccountInvoicesWithPaymentAmountViewModel,
  MyAccountLookupBy,
  MyAccountNonZeroBalanceInvoiceLookupViewModel,
  MyAccountSiteViewModel,
  PaymentLinkClient,
  PaymentTypeEnum,
  SecureCheckoutLinkEnum,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/shared/auth/auth.service";
import { saveAs } from 'file-saver';

@Component({
  selector: "app-my-account-user-create-link-invoice",
  templateUrl: "./create-link-invoice.component.html",
  styleUrls: ["./create-link-invoice.component.scss"],
})
export class CreateLinkInvoiceComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input() invoicesSelected: boolean;
  @Input() isIVR: boolean;
  @Input() accountNumberFromQuery: string;
  @Input() userEmails: string;
  @Input() userPhoneNumbers: string;

  isLoading: boolean;

  filterFormGroup = new FormGroup({
    siteId: new FormControl(null),
    email: new FormControl(null),
    phone: new FormControl(null),
  });

  userEmailsArray: string[] = [];
  userPhoneNumbersArray: string[] = [];

  showDeposits = false;

  showInvoices = false;

  account: MyAccountNonZeroBalanceInvoiceLookupViewModel;

  customerEmail = new FormControl("");
  customerPhone = new FormControl("");
  id = new FormControl(0);
  accountNumber = new FormControl("");
  invoiceNumber = new FormControl("");
  siteNumber = new FormControl("");
  deposit = new FormControl(0);

  selectedInvoiceNumbers: string[] = [];


  selectedInvoices: MyAccountInvoicesWithPaymentAmountViewModel[] = [];

  accountLoaded = false;

  currentUserId: number = 0;

  invoice = MyAccountInvoicesViewModel;

  invoices = new Array<MyAccountInvoicesViewModel>();

  subtotal: number = 0;
  guestPortalFee: number = 4.95;
  total: number = 0;
  includeGuestPortalFee: boolean = true;

  accountNumberSearched : string = "";

  showGuestPortalFee: boolean = true;

  isAllSelected: boolean = false;


  displayedInvoicesColumns: string[] = [
    "select",
    "download",
    "invoiceNumber",
    "siteId",
    "invoiceDate",
    "originalAmount",
    "dueDate",
    "balance",
    "paymentAmount"
  ];
  invoiceLoading: boolean;

  constructor(
    private myAccountAccountsClient: MyAccountAccountsClient,
    private paymentLinkClient: PaymentLinkClient,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
  ) { }


  ngOnInit() {
    this.authService.currentUser.subscribe(
      (response) => {
        this.currentUserId = response.id;
      }
    );
    this.showDeposits = false;
    this.showInvoices = false;
    if (this.accountNumberFromQuery && this.accountNumberFromQuery.length > 0) {
      this.accountNumber.setValue(this.accountNumberFromQuery);
      this.search();
    }
    if (this.userEmails && this.userEmails.length > 0) {
      this.userEmailsArray = this.userEmails.split(',');
    }
    if (this.userPhoneNumbers && this.userPhoneNumbers.length > 1) {
      this.userPhoneNumbersArray = this.userPhoneNumbers.split(',');
    }

    this.accountNumber.valueChanges.subscribe(value => {
      this.filterFormGroup.controls.siteId.setValue(null);
      this.selectedInvoices = [];
      this.recalculateSubtotal();
    });

  }

  getAssigneeId = (site: MyAccountSiteViewModel) => site.siteNumber;
  getAssigneeName = (site: MyAccountSiteViewModel) => site.siteNumber === null ? '' : site.siteNumber;

  getEmailId = (email: string) => email;
  getEmailName = (email: string) => email;

  getPhoneId = (phone: string) => phone;
  getPhoneName = (phone: string) => phone;

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onDepositClicked() {
    this.isLoading = true;
    this.showDeposits = true;
    this.showInvoices = false;
    this.isLoading = false;
  }

  onCheckboxClicked(event: any, invoice: MyAccountInvoicesWithPaymentAmountViewModel): void {
    if (event.checked && invoice) {
      this.selectedInvoices.push(invoice);
      this.subtotal += invoice.paymentAmount;
      this.total += invoice.paymentAmount;
    } else {
      this.isAllSelected= false;
      this.subtotal -= invoice.paymentAmount;
      this.total -= invoice.paymentAmount;
      this.selectedInvoices = this.selectedInvoices.filter(inv => inv.invoiceNumber !== invoice.invoiceNumber);
    }
  }
  selectAllInvoices()
  {
    this.selectedInvoices = [...this.account.invoices];
    this.account.invoices.forEach(invoice => {
      invoice.checked = true;
    });
    this.recalculateSubtotal();
  }

  toggleSelectAll(event: any) {
    const isChecked = event.checked;
    this.isAllSelected = isChecked;
    if(isChecked)
    {
      this.selectAllInvoices();
    }
    else
    {
      this.deselectAllInvoices();
    }
  }

  showDeselectAll()  {
    return this.selectedInvoices.length > 0;
  }

  deselectAllInvoices()
  {
    this.selectedInvoices = [];
    this.account.invoices.forEach(invoice => {
      invoice.checked = false;
    });
    this.recalculateSubtotal();
  }

  recalculateSubtotal(): void {
    this.subtotal = this.selectedInvoices.reduce((acc, currentInvoice) => acc + currentInvoice.paymentAmount, 0);
    this.total = this.subtotal + (this.includeGuestPortalFee ? this.guestPortalFee : 0);
  }

  createInvoiceLink() {
    if(this.accountNumber.value.trim() != this.accountNumberSearched){
      this.snackBarService.ShowError("The account number has been changed since the search. Please search again.");
      return;
    }

    if(this.subtotal < 0.01)
    {
      this.snackBarService.ShowError("Total amount must be greater than $0.00");
      return;
    }

    this.isLoading = true;

    // only require an email/phone number if the user is not using IVR
    if (!this.isIVR) {
      // Check if either customerEmail or customerPhone is valid
      const phoneControl = this.filterFormGroup.controls.phone;
      const emailControl = this.filterFormGroup.controls.email;

      // Regular expression for validating an email address
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      let isFormValid = false;

      if (phoneControl.value && emailControl.value) {
        // If both have values, check if both are valid and email matches regex
        isFormValid = phoneControl.valid && emailControl.valid && emailRegex.test(emailControl.value);
      } else if (phoneControl.value || emailControl.value) {
        // If only one has a value, check if the one with value is valid
        // For email, also check if it matches the regex
        if (phoneControl.value) {
          isFormValid = phoneControl.valid;
        } else {
          isFormValid = emailControl.valid && emailRegex.test(emailControl.value);
        }
      } else {
        // If neither have values, you might consider the form invalid or handle accordingly
        isFormValid = false; // or true, based on your requirements
      }

      if (!isFormValid) {
        this.snackBarService.ShowError("Please enter a valid email or phone number");
        this.isLoading = false;
        return;
      }
    }

    const five9CallId = sessionStorage.getItem("callId");

    const paymentLink = new CreatePaymentLinkCommand({
      customerEmailAddress: this.filterFormGroup.controls.email.value,
      customerPhoneNumber: this.filterFormGroup.controls.phone.value,
      accountNumber: this.accountNumber.value,
      totalAmount: this.subtotal,
      createdByCsrId: this.currentUserId,
      siteNumber: this.siteNumber.value,
      invoices: this.selectedInvoices,
      paymentType: this.isIVR ? PaymentTypeEnum.IVR : PaymentTypeEnum.CheckoutLink,
      linkType: SecureCheckoutLinkEnum.Invoice,
      hasConvenienceFee: this.includeGuestPortalFee,
      paymentTotalWithConvenienceFee: this.total,
      five9CallId: five9CallId,
    });

    this.subscription.add(
      this.paymentLinkClient.createPaymentLink(
        paymentLink
      ).subscribe((result) => {
        this.isLoading = false;
        if (result.isSuccess) {
          this.snackBarService.ShowSuccess("The link has been created successfully !");
          this.router.navigateByUrl("/payment-link/view/" + result.result.id);
        }
      },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError("Create Payment Link Error : " + error);
        }
      )
    );
  }


  createDepositLink() {
    this.createInvoiceLink();
  }


  onInvoicesClicked() {
    this.isLoading = true;
    // query to get invoices to display based on site/account number

    this.showInvoices = true;
    this.showDeposits = false;
    this.isLoading = false;
  }

  get myAccountAccountFound() {
    return this.accountLoaded;
  }

  get isValidAccountNumber() {
    return (this.accountNumber.value || "").trim().length > 0;
  }

  get isValidSiteNumber() {
    return (this.siteNumber.value || "").trim().length > 0;
  }

  get isValidLengthAccountNumber() {
    return (this.accountNumber.value || "").trim().length > 0 && ((this.accountNumber.value || "").trim().length < 9 || (this.accountNumber.value || "").trim().length > 9);
  }


  search() {
    this.isLoading = true;
    this.account = null;

    var number = "";
    var lookupBy: MyAccountLookupBy;

    if (this.isValidAccountNumber) {
      number = this.accountNumber.value.trim();
      lookupBy = MyAccountLookupBy.AccountNumber;
    }
    else if (this.isValidSiteNumber) {
      number = this.siteNumber.value.trim();
      lookupBy = MyAccountLookupBy.SiteNumber;
    }
    else {
      this.isLoading = false;
      this.snackBarService.ShowError(
        "Please enter a value for one of the fields."
      );
      return;
    }


    this.paymentLinkClient
      .searchForMyAccountInvoices(number, lookupBy)
      .subscribe(
        (x) => {
          if (x.result == null) {
            this.isLoading = false;
            this.snackBarService.ShowError(
              "No account found for the entered number. Please try again."
            );
            return;
          }
          this.account = x.result;
          this.includeGuestPortalFee = this.account.billingRegion == "US";
          this.showGuestPortalFee = this.account.billingRegion == "US";
          this.total = this.includeGuestPortalFee ? this.guestPortalFee : 0;
          this.sortInvoices();
          this.accountLoaded = true;
          this.isLoading = false;
          this.accountNumber.setValue(this.account.accountNumber);
          this.accountNumberSearched = this.accountNumber.value.trim();
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(
            "Search for MyAccount Account Error: " + error
          );
        }
      );

    this.isLoading = false;
  }

  onDownloadPdfClicked(invoice: MyAccountInvoicesViewModel) {
    this.invoiceLoading = true;
    this.myAccountAccountsClient.getInvoicePdf(invoice.invoiceNumber, invoice.siteId ? invoice.siteId : undefined)
      .subscribe(
        (x) => {
          saveAs(x.data, invoice.invoiceNumber)
          this.invoiceLoading = false;
        },
        (error) => {
          this.invoiceLoading = false;
          this.snackBarService.ShowError(
            "Returning PDF error: " + error
          );
        }
      );
  }


  sortInvoices() {
    if (this.myAccountAccountFound) {
      var unsortedInvoices = this.account.invoices;
      unsortedInvoices.sort((a: MyAccountInvoicesViewModel, b: MyAccountInvoicesViewModel) => (
        (new Date(b.invoiceDate).getTime() - new Date(a.invoiceDate).getTime())));
      this.account.invoices = unsortedInvoices;
    }
  }

}

